import React, {Fragment, useState, useEffect, useRef} from "react";
import {
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggl,
  Container,
  Col,
  Media,
  FormGroup
} from "reactstrap";
import UserAvatar from "react-user-avatar";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const tempVideo = require("../../assets/images/video.mp4");

const AppStoreLinks = props => {
  const {} = props;
  useEffect(() => {}, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // height: window.innerHeight,
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: "white",
        // marginLeft: 25,
        marginRight: 25,
        textAlign: "center",
        alignItems: "center"
      }}
    >
      <div>Find and add your comments to this topic on Vare App</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row"
        }}
      >
        <button
          style={{
            width: 100,
            margin: 5,

            border: "1px solid gray"
          }}
          key={"sjdhssccwww"}
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.vote.keyVoteApp"
            );
          }}
          href="#"
          className={`tab-link`}
        >
          {"Android"}
        </button>
        <button
          style={{
            width: 100,
            margin: 5,
            border: "1px solid gray"
          }}
          key={"sjdhsdsd"}
          onClick={() => {
            window.open("https://apps.apple.com/app/id1503031565");
          }}
          href="#"
          className={`tab-link`}
        >
          {"IOS"}
        </button>
      </div>
    </div>
  );
};

export default AppStoreLinks;
