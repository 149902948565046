import React from "react";
import { GoogleLogin } from "react-google-login";

const GLogIn = ({ clientId, onSuccess, onFailure }) => {
  return (
    <div id="googleSignInButton" style={{ width: "90%", paddingBottom: '15px', paddingRight: '9px', justifyContent: 'center' }}>
      <GoogleLogin
        clientId={clientId}
        buttonText="Continue With Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
      />
    </div>
  );
};

export default GLogIn;
