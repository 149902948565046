import React from "react";
import "./login.css";
import FacebookLogin from "react-facebook-login";

const FbLogIn = ({componentClicked, responseFacebook}) => {
  return (
    <div
      id="fbSignInButton"
      style={{
        width: "-webkit-fill-available",
        paddingBottom: "10px",
        paddingRight: "50px"
      }}
    >
      <FacebookLogin
        appId="1101818780762648"
        autoLoad={false}
        fields="name,email,picture"
        onClick={componentClicked}
        callback={responseFacebook}
        cssClass="loginBtn loginBtn--facebook"
      />
    </div>
  );
};

export default FbLogIn;
