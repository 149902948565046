import React, {Fragment, useState, useEffect, useRef} from "react";
import {Row, Card} from "reactstrap";
import "../select.css";
import TownCryerListCard from "./TownCryerListCard";
import queryString from "query-string";
import {CommentSection} from "react-comments-section";
import PageMenu from "../PageMenu";
import RESTCall from "../../../redux/actions/restApi";
import cookie from "react-cookies";
import {useHistory} from "react-router-dom";
import TextInput from "react-autocomplete-input";
import moment from "moment";
import "./comment.css";
import "../../info.css";
import "../../../assets/css/sass/_gogo.style.scss";
import styled from "styled-components";
import io from "socket.io-client";
import {ThemeColors} from "../../../helpers/ThemeColors";
import {groupBy} from "../../../helpers";
import useLocalStorage from "../localStorage";
import CustomInput from "./CustomInput";

import data from "./data.json";
import Editable from "./Editable";

const NewsModal = ({
  setLoginUser,
  loginUser,
  info,
  onHandleQuery,
  activeUser,
  setActiveUser
}) => {
  const [comment, setComment] = useState(data);
  let count = 0;
  comment &&
    comment.length > 0 &&
    comment.map(i => {
      count += 1;
      i.replies && i.replies.map(i => (count += 1));
    });
  const [textEditable, setTextEditable] = useState("");
  const history = useHistory();
  const [refreshing, setRefreshing] = useState(false);
  const [detailsIndex, setDetailsIndex] = useState(-1);

  const [searchText, setSearchText] = useState("");
  const [real, setReal] = useState({
    _id: "6325b817b5841a00232837b4",
    source: {
      id: "fox-news",
      name: "Fox News"
    },
    author: "Elizabeth Pritchett",
    title:
      "'Phantom of the Opera' announces end to historic 35-year run on Broadway - Fox News",
    description:
      "After 35 years on Broadway in New York, longest-running musical 'Phantom of the Opera' has announced it will perform its last show at The Majestic Theater in February 2023.",
    url:
      "https://www.foxnews.com/entertainment/phantom-of-the-opera-end-35-year-run-broadway-2023",
    urlToImage:
      "https://static.foxnews.com/foxnews.com/content/uploads/2022/09/GettyImages-112465590.jpg",
    publishedAt: "2022-09-17T08:57:38Z",
    content:
      'After 35 years, one of Broadway\'s best-known shows will close its curtains for the final time in 2023.\r\nThe "Phantom of the Opera" announced Friday its last performance will be on Feb. 18 at The Maje… [+742 chars]',
    batchcode: "Sat Sep 17 "
  });
  const urlSearch = useRef(null);

  const [userSource, setUserSource] = useState(false);
  const [storeUser, setStoreUser] = useLocalStorage("user");

  const userId = "01a";
  const avatarUrl = "https://ui-avatars.com/api/name=Riya&background=random";
  const name = "xyz";

  const saveNews = async articles => {
    const request = {
      _id: "6325b817b5841a00232837b4",
      source: {
        id: "fox-news",
        name: "Fox News"
      },
      author: "Elizabeth Pritchett",
      title:
        "'Phantom of the Opera' announces end to historic 35-year run on Broadway - Fox News",
      description:
        "After 35 years on Broadway in New York, longest-running musical 'Phantom of the Opera' has announced it will perform its last show at The Majestic Theater in February 2023.",
      url:
        "https://www.foxnews.com/entertainment/phantom-of-the-opera-end-35-year-run-broadway-2023",
      urlToImage:
        "https://static.foxnews.com/foxnews.com/content/uploads/2022/09/GettyImages-112465590.jpg",
      publishedAt: "2022-09-17T08:57:38Z",
      content:
        'After 35 years, one of Broadway\'s best-known shows will close its curtains for the final time in 2023.\r\nThe "Phantom of the Opera" announced Friday its last performance will be on Feb. 18 at The Maje… [+742 chars]',
      batchcode: "Sat Sep 17 "
    };

    const formData = {
      request: "insert",
      query: request,
      resource: "vare_comments_feed",
      check: ["publishedAt", "author"]
    };
    return RESTCall.axiosQuery(formData).then(response => {
      return response;
    });
  };

  const getNews = () => {
    const url = urlSearch.current.value;
    //get meta data
    // reformart meta date to "setReal"
    //save meta data using saveNews()
  };

  const saveMessageLikes = async message => {
    const temp = {...message};
    delete temp["_id"];
    const formData = {
      request: "insert",
      query: {
        ...temp
      }, //add table key value to edit
      resource: "vare_comments_feed", //add table name
      check: ["author", "publishedAt"]
    };
    return await RESTCall.axiosQuery(formData).then(contents => {
      // console.log("contents", contents);
      getNews();
      // const notice = contents && contents.data && contents.data;
      return contents;
    });
  };

  const searchLink = () => {
    const url = urlSearch.current.value;
    //search url metadata
  };

  const customInputFunc = props => {
    return (
      <CustomInput
        parentId={props.parentId}
        cancellor={props.cancellor}
        value={props.value}
        edit={props.edit}
        submit={props.submit}
        handleCancel={props.handleCancel}
      />
    );
  };

  const editable = props => {
    return (
      <Editable
        value={textEditable}
        onChange={evt => {
          setTextEditable(evt.target.value);
        }}
        onCancel={evt => {
          setTextEditable("");
        }}
        onPost={evt => {
          console.log("onPost >", textEditable);
        }}
      />
    );
  };

  return (
    <React.Fragment>
      {userSource && userSource == "app" ? null : <PageMenu />}
      <div
        style={{
          display: "flex",
          marginTop: 65,
          height: 200,
          backgroundRepeat: "repeat-x",
          backgroundImage: `url(${"https://varefiles.s3.us-east-2.amazonaws.com/podcastinfo.jpg"}`,
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            marginTop: 50,
            width: 380,
            height: 30,
            flexDirection: "row",
            backgroundColor: "rgba(0,0,0,.69)",
            // backgroundColor: "#f2f3f5",
            borderRadius: 25
          }}
        >
          <div>
            <TextInput
              ref={urlSearch}
              style={{
                // backgroundColor: "rgba(0,0,0,.19)",
                borderWidth: 0,
                // height: 30,
                width: 300,
                textAlign: "center",
                // paddingTop: 10,
                fontSize: 18,
                color: "white"
              }}
              // placeholderTextColor="white"
              placeholder={"Enter your link here"}
              onChange={text => {}}
            />{" "}
          </div>
          <div>
            <i
              onClick={() => {
                // saveNews();
                getNews();
              }}
              style={{
                padding: 10,
                borderRadius: 10,
                color: "white"
              }}
              className={`fas fa-x fa-search`}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          // paddingTop: 90,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 20
          }}
        >
          {"360 News Updates"}
        </div>

        <div
          style={{
            backgroundColor: "gray",
            borderRadius: 25,
            width: "90%",
            paddingBottom: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          {real && (
            <TownCryerListCard
              storeUser={storeUser}
              saveMessageLikes={saveMessageLikes}
              // shareItem={shareItem}
              detailsIndex={detailsIndex}
              // videoUrl={videoUrl}
              real={real}
              index={0}
            />
          )}

          <CommentSection
            currentUser={
              userId && {userId: userId, avatarUrl: avatarUrl, name: name}
            }
            commentsArray={comment}
            setComment={setComment}
            // signinUrl={signinUrl}
            // signupUrl={signupUrl}
            customInput={customInputFunc}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const styleInfo = {
  wrapPadMydiv: {
    margin: 10,
    paddingTop: 60,
    fontSize: 10,
    color: "#1c1e21",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  },
  wrapMydiv: {
    fontSize: 10,
    color: "#1c1e21",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  }
};

export default NewsModal;
