import React, {Component, Fragment, useState, useEffect, useRef} from "react";
import routes from "./routes";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
  useLocation,
  useHistory
} from "react-router-dom";
import PageFooter from "./pages/VideoUI/PageFooter";
import queryString from "query-string";

// Import Scss
import "./theme.scss";

//Import Icon Css
import "./assets/css/materialdesignicons.min.css";

const App = props => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          {routes.map((route, idx) => (
            <Route
              path={`${route.path}`}
              component={route.component}
              key={idx}
            />
          ))}
        </Switch>
      </Router>
      <PageFooter />
    </React.Fragment>
  );
};

export default withRouter(App);
