import React, {Fragment, useState, useEffect, useRef} from "react";
import {
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggl,
  Container,
  Col,
  Media,
  FormGroup,
  Progress,
  CardTitle,
  Label,
  Button
} from "reactstrap";
import {Formik, Form, Field} from "formik";
import UserAvatar from "react-user-avatar";
import Select from "react-select";
import CustomSelectInput from "../mycomponents/common/CustomSelectInput";
import "./select.css";
import ImageUploader from "react-images-upload";
import ContentModal from "./ContentModal";
import NewsCardList from "./NewsCardList";
import queryString from "query-string";

import PageModal from "./PageModal";
import {RWebShare} from "react-web-share";

import CommentsMobile from "./CommentsMobile";
import PageMenu from "./PageMenu";
import MediaCard from "./MediaCard";
import PageFooter from "./PageFooter";
import RESTCall from "../../redux/actions/restApi";
import ModalVideo from "react-modal-video";

// import {Helmet} from "react-helmet";
import cookie from "react-cookies";
import PlaceholderMessage from "./PlaceholderMessage";

import {useHistory} from "react-router-dom";

import VideoConfRoom from "./VideoConfRoom";
import HoverVideoPlayer from "react-hover-video-player";
import ScrollMenuPills from "./ScrollMenuPills";
import TextInput from "react-autocomplete-input";
import ReactDOM from "react-dom";
import Modal from "react-modal";

import moment from "moment";

// import ReactPlayer from 'react-player/youtube'
import ReactPlayer from "react-player";
import "../style.css"; // Tell webpack that Button.js uses these styles
import CommentsBlock from "simple-react-comments";

import "../info.css";
import "../../assets/css/sass/_gogo.style.scss";
import styled from "styled-components";

import io from "socket.io-client";

import Loader from "react-loader-spinner";

import {Browserdiv, Mobilediv, isBrowser, isMobile} from "react-device-detect";

import {Colxx, Separator} from "../mycomponents/common/CustomBootstrap";

import {ThemeColors} from "../../helpers/ThemeColors";
import {groupBy} from "../../helpers";

//Import Section Title

import Axios from "axios";

import useLocalStorage from "./localStorage";

// import "react-slideshow-image/dist/styles.css";
const colors = ThemeColors();

const map = require("../../assets/images/features/map.png");
const pics = require("../../assets/images/04.jpg");
const pics5 = require("../../assets/images/05.jpg");
const tempVideo = require("../../assets/images/video.mp4");
const StyledVideo = styled.video`
  /* flexDirection:'row', */
  height: 50%;
  width: 45%;
`;

const slideImages = [
  "images/slide_2.jpg",
  "images/slide_3.jpg",
  "images/slide_4.jpg"
];

var audio = new Audio(
  "https://varefiles.s3.us-east-2.amazonaws.com/bgmusic.mp3"
);

const Slide = props => {
  return (
    <div style={{position: "absolute", width: "100%", left: -10}}>
      {props.children}
    </div>
  );
};

const NewsModal = ({
  setLoginUser,
  loginUser,
  info,
  onHandleQuery,
  activeUser,
  setActiveUser
  // contents,
  // meetingKey,
  // host,
  // meetingId,
  // height,
  // width,
  // setUser,
  // saveComment,
  // updateComment,
  // messages
}) => {
  const [videos, setVedeos] = useState([1, 2, 3, 4]);
  const [playIndex, setPlayIndex] = useState(-1);

  const history = useHistory();
  const [refreshing, setRefreshing] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailsIndex, setDetailsIndex] = useState(-1);

  const [podcastMedia, setPodcastMedia] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [searchPodcastMedia, setSearchPodcastMedia] = useState([]);
  const [storedPodcastMedia, setStoredPodcastMedia] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [userSource, setUserSource] = useState(false);
  const [storeUser, setStoreUser] = useLocalStorage("user");
  const [height, setWindowHeight] = useState(window.innerHeight + "px");
  const [width, setWindowWidth] = useState(window.innerWidth + "px");
  const [activeNav, setActiveNav] = useState("VareHall");

  // const [selectedLike, setSelectedLike] = useState(-1);
  const [errors, setErrors] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState({});
  const [shareItem, setShareItem] = useState({});

  const [progress, setProgress] = useState("getUpload");
  const [errorMessage, setErrorMessage] = useState("");

  const [noticeUsers, setNoticeUsers] = useState([]);
  const myTitleRef = useRef("");
  const myMessageRef = useRef("");
  const pathurl = useRef(history.location);

  // const inViewVideoIndex = useRef(null);
  useEffect(() => {
    const query = queryString.parse(pathurl.current.search);
    // console.log("INFO", pathurl.current);
    if (query && query.app && query.app == "vare") setUserSource("app");
    // if (window.location.href.includes("localhost")) getNewsAPI();
    getNews();
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setPodcastMedia(
        searchPodcastMedia.length > 0 ? [...searchPodcastMedia] : []
      );
    } else {
      setPodcastMedia([...storedPodcastMedia]);
    }
    // console.log("storedPodcastMedia", storedPodcastMedia);
    // console.log("searchPodcastMedia", searchPodcastMedia);
  }, [storedPodcastMedia, searchPodcastMedia, searchText]);

  useEffect(() => {
    // console.log("selectedValue", selectedValue);
  }, [selectedValue]);
  useEffect(() => {
    // console.log("detailsIndex", detailsIndex);
  }, [detailsIndex]);

  useEffect(() => {
    // console.log("podcastMedia", podcastMedia);
  }, [podcastMedia, shareItem]);

  const handleAllNotice = () => {};

  const getNewsAPI = async () => {
    const newsurl =
      "https://newsapi.org/v2/top-headlines?country=us&apiKey=904fe7cb2cd4412593c3326ccc240c04";
    let result = await fetch(newsurl).then(response => response.json());
    if (result && result.articles) saveNews(result.articles);
    return result.articles;
  };

  const saveNews = async articles => {
    return await Promise.all(
      articles.map(rep => {
        const formData = {
          request: "insert",
          query: rep,
          resource: "vare_news_feeds",
          check: ["publishedAt", "author"]
        };
        return RESTCall.axiosQuery(formData).then(response => {
          return response;
        });
      })
    );
  };

  const getNews = () => {
    // const formData = {
    //   request: "get",
    //   resource: "vare_news_feeds",
    //   // orderBy: "dsc",
    //   sortBy: "publishedAt"
    // };
    const formData = {
      request: "search",
      query: {
        name: null
      },
      resource: "vare_news_feeds",
      id: "",
      orderBy: "dsc",
      sortBy: "publishedAt"
    };
    RESTCall.axiosQuery(formData).then(response => {
      console.log("response", response);

      const data =
        response && response.data && response.data.length > 0
          ? response.data.map(rep => {
              if (rep.likes) rep["likes"] = JSON.parse(rep.likes);
              return rep;
            })
          : [];
      if (data.length > 0) setStoredPodcastMedia([...data]);
    });
  };

  const saveMessageLikes = async message => {
    const temp = {...message};
    delete temp["_id"];
    const formData = {
      request: "insert",
      query: {
        ...temp
      }, //add table key value to edit
      resource: "vare_news_feeds", //add table name
      check: ["author", "publishedAt"]
    };
    return await RESTCall.axiosQuery(formData).then(contents => {
      // console.log("contents", contents);
      getNews();
      // const notice = contents && contents.data && contents.data;
      return contents;
    });
  };

  return (
    <React.Fragment>
      {userSource && userSource == "app" ? null : <PageMenu />}
      <div
        style={{
          display: "flex",
          marginTop: 65,
          height: 200,
          backgroundRepeat: "repeat-x",
          backgroundImage: `url(${"https://varefiles.s3.us-east-2.amazonaws.com/podcastinfo.jpg"}`,
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            marginTop: 50,
            width: 380,
            height: 30,
            flexDirection: "row",
            backgroundColor: "rgba(0,0,0,.69)",
            // backgroundColor: "#f2f3f5",
            borderRadius: 25
          }}
        >
          <div>
            <TextInput
              value={searchText}
              style={{
                // backgroundColor: "rgba(0,0,0,.19)",
                borderWidth: 0,
                // height: 30,
                width: 300,
                textAlign: "center",
                // paddingTop: 10,
                fontSize: 18,
                color: "white"
              }}
              // placeholderTextColor="white"
              placeholder={"Search"}
              onChange={text => {
                const newItem =
                  podcastMedia &&
                  podcastMedia.length > 0 &&
                  podcastMedia.filter(rep => {
                    return JSON.stringify(rep)
                      .toLowerCase()
                      .includes(text.toLowerCase());
                  });
                // console.log("newItem", newItem);
                setSearchPodcastMedia(newItem);
                setSearchText(text);
              }}
            />{" "}
          </div>
          <div>
            <i
              onClick={() => {
                setSearchText("");

                // alert("Not found");
              }}
              style={{
                padding: 10,
                borderRadius: 10,
                color: "white"
              }}
              className={`fas fa-x fa-sync-alt`}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          // paddingTop: 90,
          width: "100%"
          // alignItems: "center",
          // justifyContent: "center"
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 20
          }}
        >
          {"360 News Updates"}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          // backgroundColor: "red",
          width: "100%",
          justifyContent: "center",
          alignSelf: "center"
        }}
      >
        <div
          style={{
            alignSelf: "center",
            display: "inline-block",
            maxWidth: 1000
          }}
        >
          <div
            className="row"
            style={{
              paddingBottom: 80
            }}
          >
            {" "}
            {videoUrl && videoUrl.video ? (
              <PageModal
                activeUser={activeUser}
                setActiveUser={() => {
                  setModalIsOpen(false);
                  setVideoUrl({});
                }}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                myWidth={350}
                myHeight={400}
              >
                <video
                  width="100%"
                  height="240"
                  key={videoUrl._id}
                  controls
                  autoPlay
                >
                  <source src={videoUrl.video} />
                </video>
              </PageModal>
            ) : null}
            {podcastMedia && podcastMedia.length > 0
              ? podcastMedia.map((real, index) => {
                  return (
                    <Colxx key={"jghfgft" + index} sm={12} lg={6}>
                      <div
                        key={"fdkvldhsgj" + index}
                        style={{
                          display: "flex"
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            right: 60,
                            bottom: "15%",
                            zIndex: 11
                          }}
                        >
                          <i
                            className={`fas fa-2x fa-${
                              detailsIndex == index ? "eye" : "eye-slash"
                            }`}
                            onClick={() => {
                              setDetailsIndex(
                                detailsIndex == index ? -1 : index
                              );
                            }}
                            style={{
                              // paddingTop: 2,
                              // fontSize: 12,
                              // paddingLeft: 6,
                              // fontWeight: "bold",
                              color: "#2096F3"
                            }}
                          />
                        </div>
                        <NewsCardList
                          storeUser={storeUser}
                          saveMessageLikes={saveMessageLikes}
                          shareItem={shareItem}
                          detailsIndex={detailsIndex}
                          videoUrl={videoUrl}
                          real={real}
                          index={index}
                        />
                      </div>
                    </Colxx>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const styleInfo = {
  wrapPadMydiv: {
    margin: 10,
    paddingTop: 60,
    fontSize: 10,
    color: "#1c1e21",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  },
  wrapMydiv: {
    fontSize: 10,
    color: "#1c1e21",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  }
};

export default NewsModal;
