// import Index1 from "./pages/Index1/Index1";
// import Index2 from "./pages/Index2/Index2";
// import Index3 from "./pages/Index3/Index3";
// import Index4 from "./pages/Index4/Index4";
// import Index5 from "./pages/Index5/Index5";
// import Index6 from "./pages/Index6/Index6";
// import Index10 from "./pages/Index10/Index";
// import  Varehall from "./pages/Index";

import ScheduleUI from "./pages/VideoUI/ScheduleModal";
import MeetingUI from "./pages/VideoUI/MeetingModal";
// import ConfRoomUI from "./pages/VideoUI/VideoConfRoom";
import LoginUI from "./pages/VideoUI/login";
import TownCryerStoryModalUI from "./pages/VideoUI/TownCryerStoryModal";
import TownCryerModalUI from "./pages/VideoUI/towncryer/TownCryerModal";

import NewsUI from "./pages/VideoUI/NewsModal";

import PodcastUI from "./pages/VideoUI/PodcastModal";
import JeopardyUI from "./pages/VideoUI/JeopardyModal";

import StoryUI from "./pages/VideoUI/StoryModal";

import NoticeUI from "./pages/VideoUI/Notice";
import ProfileUI from "./pages/VideoUI/Profile";
import StatsUI from "./pages/VideoUI/StatsModal";
import RegisterUI from "./pages/VideoUI/Register";
import AddContent from "./pages/VideoUI/AddContent";

import ContentUI from "./pages/VideoUI/ContentModal";
import CalendarUI from "./pages/VideoUI/CalendarModal";
import AppsUI from "./pages/VideoUI/AppsModal";
import ChartUI from "./pages/ChartUI";

const routes = [
  // { path: "/index6", component: Index6 },
  // { path: "/index5", component: Index5 },
  // { path: "/index4", component: Index4 },
  // { path: "/index3", component: Index3 },
  // { path: "/index2", component: Index2 },
  // {path: "/", component: AppsUI},
  // {path: "/chart", component: ChartUI},
  // {path: "/member", component: VareMember},
  // {path: "/media", component: MediaUI},
  // {path: "/content", component: ContentUI},
  {path: "/schedule", component: ScheduleUI},

  {path: "/notice", component: NoticeUI},

  {path: "/podcast", component: PodcastUI},

  {path: "/chart", component: ChartUI},

  {path: "/story", component: StoryUI},

  {path: "/news", component: NewsUI},
  {path: "/meeting", component: MeetingUI},

  {path: "/towncryer", component: TownCryerModalUI},
  {path: "/townstory", component: TownCryerStoryModalUI},

  {path: "/stats", component: StatsUI},
  {path: "/register", component: RegisterUI},
  {path: "/profile", component: ProfileUI},
  {path: "/login", component: LoginUI},
  {path: "/logout", component: LoginUI},
  {path: "/authdelete", component: LoginUI},
  {path: "/jeopardy", component: JeopardyUI},

  // {path: "/confroom", component: ConfRoomUI},
  {path: "/", component: PodcastUI}
  // {path: "/apps", component: AppsUI},
  // {path: "/", component: AppsUI}
];

export default routes;
