import React from "react";
import { GoogleLogout } from "react-google-login";
import ls from "local-storage";

const GLogOut = ({ clientId }) => {
  const onSuccess = () => {
    ls.set("loginType", "");
    window.location.reload();
    console.log("Log out successful!");
  };

  return (
    <div id="signOutButton" style={{ marginLeft: "20px" }}>
      <GoogleLogout
        clientId={clientId}
        buttonText={"Logout"}
        onLogoutSuccess={onSuccess}
      />
    </div>
  );
};

export default GLogOut;
