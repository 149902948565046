import React, {Fragment, useState, useEffect, useRef} from "react";
import {
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggl,
  Container,
  Col,
  Media,
  FormGroup,
  Progress,
  CardTitle,
  Label,
  Button
} from "reactstrap";
import moment from "moment";

import {RWebShare} from "react-web-share";

const CardNewsList = ({
  storeUser,
  saveMessageLikes,
  shareItem,
  detailsIndex,
  videoUrl,
  real,
  index
}) => {
  return (
    <Card
      style={{
        borderColor: videoUrl == index ? "red" : "",
        width: "95%",
        height: 250,
        padding: 10,
        flexDirection: "row"
      }}
    >
      <img
        onClick={() => {
          real.url && window.open(real.url, "_self");
          // setVideoUrl(index);
          // setModalIsOpen(true);
        }}
        // height={100}
        width={"100%"}
        src={
          real.urlToImage
            ? real.urlToImage
            : "https://varefiles.s3.us-east-2.amazonaws.com/meetingpic1.jpg"
        }
        // src={rep.user_id && `https://varefiles.s3.us-east-2.amazonaws.com/${rep.user_id.replace('.com','.jpg')}`}
      />

      {detailsIndex == index ? null : (
        <div
          onClick={() => {
            real.url && window.open(real.url, "_self");
            // setVideoUrl(index);
            // setModalIsOpen(true);
          }}
          style={{
            position: "absolute",
            bottom: 10,
            backgroundColor: "rgba(0,0,0,.80)",
            width: "95%",
            flexDirection: "column",
            paddingLeft: 10,
            color: "white"
          }}
        >
          <div
            style={{
              width: "70%",
              fontSize: 14,
              fontWeight: "bold",
              color: "red",
              display: "flex",
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <div
              style={{
                paddingRight: 6
              }}
            >
              {" "}
              {real.author}
            </div>

            <i
              className={`fas fa-link`}
              onClick={() => {
                real.url && window.open(real.url, "_self");
              }}
              style={{
                paddingTop: 2,
                fontSize: 12,

                color:
                  real.source && real.source.name
                    ? "#2096F3"
                    : real.meeting_id && real.meeting_id && real.date
                    ? "white"
                    : "white"
              }}
            >
              {` ${
                real.source && real.source.name
                  ? real.source.name
                  : real.meeting_id && real.meeting_id && real.date
                  ? real.date
                  : ""
              }`}
            </i>
          </div>
          <div
            style={{
              fontSize: 14,
              width: "90%",
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            {real.title ? real.title.substring(0, 100) + "..." : ""}
          </div>

          <div
            style={{
              fontWeight: "bold",
              width: "100%",
              fontSize: 12,
              color: "#FA8072",
              display: "flex",
              flexDirection: "row"
            }}
          >
            {/*<div>{`views: ${
              real.views
                ? real.views +
                  (real.likes && real.likes.length
                    ? real.likes.length * 1.5
                    : 0)
                : real.likes && real.likes.length
                ? real.likes.length * 3
                : 0
            }`}</div>
            <div style={{paddingLeft: 20}}>{`   likes: ${
              real.likes && real.likes.length ? real.likes.length : 0
            }`}</div>*/}
            <div style={{paddingLeft: 20}}>
              {real.date
                ? moment(real.date || moment.now()).fromNow()
                : real.publishedAt
                ? moment(real.publishedAt || moment.now()).fromNow()
                : ""}
            </div>
          </div>
        </div>
      )}
      {detailsIndex == index ? null : (
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: 10,
            paddingTop: 15
          }}
        >
          <div>
            <i
              onClick={() => {
                real.url && window.open(real.url, "_self");
                // setVideoUrl(index);
                // setModalIsOpen(true);
              }}
              style={{
                backgroundColor: "red",
                padding: 10,
                borderRadius: 10
              }}
              className={`fas fa-play`}
            />
          </div>
          <div
            style={{
              paddingLeft: 15
            }}
          >
            <RWebShare
              data={{
                text: real.shareText
                  ? real.shareText
                  : "News Updates Shared from Vare",
                url: real.shareUrl ? real.shareUrl : window.location.href,
                title: real.title
              }}
              onClick={() => {
                shareItem(index);
              }}
            >
              <i
                style={{
                  backgroundColor: "#f2f3f5",
                  padding: 10,
                  borderRadius: 10
                }}
                className={`fas fa-share-alt`}
              />
            </RWebShare>
          </div>
          <div style={{paddingLeft: 15, paddingRight: 10}}>
            <i
              onClick={() => {
                if (!(storeUser && storeUser.email)) {
                  alert("Please login to use this feature");
                  return;
                }
                saveMessageLikes({
                  ...real,
                  likes: !real.likes
                    ? JSON.stringify([storeUser.user_id])
                    : real.likes && real.likes.includes(storeUser.user_id)
                    ? JSON.stringify(
                        real.likes.filter(res => res != storeUser.user_id)
                      )
                    : JSON.stringify([...real.likes, storeUser.user_id])
                });
              }}
              style={{
                backgroundColor: "#f2f3f5",
                padding: 10,
                borderRadius: 10,
                color:
                  real.likes &&
                  storeUser.user_id &&
                  real.likes.includes(storeUser.user_id)
                    ? "#2096F3"
                    : "black"
              }}
              className={`fas fa-heart`}
            />
          </div>
        </div>
      )}
    </Card>
  );
};

const styleInfo = {
  wrapPadMydiv: {
    margin: 10,
    paddingTop: 60,
    fontSize: 10,
    color: "#1c1e21",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  },
  wrapMydiv: {
    fontSize: 10,
    color: "#1c1e21",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  }
};

export default CardNewsList;
