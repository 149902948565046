import React from "react";

const fbLogoutBtnStyle = {
  border: "none",
  borderRadius: "10px",
  backgroundColor: "hsl(214deg 100% 59%)",
  color: "white",
};

const FbLogOut = ({ handleLogOutFacebook }) => {
  return (
    <button
      style={fbLogoutBtnStyle}
      className="fb__logout__btn"
      onClick={handleLogOutFacebook}
    >
      Logout
    </button>
  );
};

export default FbLogOut;
